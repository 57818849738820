import { useDriverStore } from "~/store/DriverStore";

const useDriver = () =>{
    return {
        initDriver : (elements:any, tourName:any) =>{
            // @ts-ignore
            return  useNuxtApp().$driver.startTour(elements, tourName);
        },
        driverStore: useDriverStore()
    }
}

export default useDriver;