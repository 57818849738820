const useDashboardDriver = () => {
    return {
        elements : [
            { element: 'ul .menu-item:nth-child(1)', title: 'Dashboard', description: 'The dashboard, or the main area of the screen, gives a quick overview of the key widgets, tools and critical data needed to manage content, events and other operations of the ISMG network.' },
            { element: 'ul .menu-item:nth-child(2)', title: 'Content', description: 'The content hub for creating and publishing diverse content types across all media properties.' },
            { element: 'ul .menu-item:nth-child(3)', title: 'Training', description: 'A comprehensive management center for live and recorded webinar training resources.' },
            { element: 'ul .menu-item:nth-child(4)', title: 'Users', description: 'The central control tab for managing website user administration, including memberships and user groups.' },
            { element: 'ul .menu-item:nth-child(5)', title: 'User Registration Policy', description: 'This section allows you to manage settings such as bad words, user flagging and company information for better user categorization and compliance.' },
            { element: 'ul .menu-item:nth-child(6)', title: 'Newsletter', description: 'This functionality allows you to seamlessly oversee referral programs for effective audience engagement.' },
            { element: 'ul .menu-item:nth-child(7)', title: 'Marketing', description: 'A suite of tools for managing pop-up ads and vendor directories, with customization options and asset-based settings.' },
            { element: 'ul .menu-item:nth-child(8)', title: 'Tools', description: 'A collection of access utilities and integrations designed to enhance CMS functionality.' },
            { element: 'ul .menu-item:nth-child(9)', title: 'Reports', description: 'This tab helps users generate in-depth reports on content performance and user engagement.' },
            { element: 'ul .menu-item:nth-child(10)', title: 'ISMG Client Services', description: 'A dedicated space for managing authors, board of advisors, campaigns, athena asset keywords and client accounts.' },
            { element: '#screen-recording', title: 'Record CMS Screen', description: 'Something went wrong! You can now record the CMS screen by clicking this button. To stop recording, click the same button again. Once you stop, a download button will appear on the left, allowing you to save the recording. You can then attach the video to your support ticket.' },
            ],
    }
}

export default useDashboardDriver;